var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "investments-cadre-details-list"
  }, _vm._l(_vm.sortedBrigades, function (brigade) {
    return _c('div', {
      key: brigade.id,
      staticClass: "brigade"
    }, [_c('vs-form-header', {
      attrs: {
        "large": "",
        "with-button": _vm.editable,
        "text": brigade.name,
        "buttonText": "+ Dodaj pracownika",
        "buttonPermission": "investment.brigade.assign_employee"
      },
      on: {
        "action": function () {
          return _vm.showAssignModal(brigade);
        }
      },
      scopedSlots: _vm._u([{
        key: "subtitle",
        fn: function () {
          return [brigade.is_management ? _c('div', {
            staticClass: "brigade-subtitle"
          }, [_vm._v(" Brygada kierownicza ")]) : _vm._e(), brigade.resource.find(function (v) {
            return v.pivot.is_main === 1;
          }) ? _c('div', {
            staticClass: "brigade-subtitle"
          }, [_vm._v(" Maszyna główna: "), _c('base-resource-label', {
            attrs: {
              "id": _vm.getBrigadesMainVehicle(brigade).id,
              "resource": _vm.getBrigadesMainVehicle(brigade)
            }
          })], 1) : _vm._e(), brigade.resource.filter(function (_ref) {
            var pivot = _ref.pivot;
            return pivot.is_main !== 1;
          }).length ? _c('div', {
            staticClass: "brigade-subtitle"
          }, [_vm._v(" Zasoby dodatkowe: "), _vm._l(brigade.resource.filter(function (_ref2) {
            var pivot = _ref2.pivot;
            return pivot.is_main !== 1;
          }), function (resource) {
            return _c('base-resource-label', {
              key: resource.id,
              attrs: {
                "id": resource.resource.id,
                "resource": resource.resource
              }
            });
          })], 2) : _vm._e()];
        },
        proxy: true
      }, _vm.editable ? {
        key: "edit-button",
        fn: function () {
          return [_c('table-action-button', {
            attrs: {
              "dropdown-variant": "transparent",
              "items": _vm.brigadeEditActions,
              "target": brigade
            }
          })];
        },
        proxy: true
      } : null], null, true)
    }), brigade.employees.length ? _c('vs-table', {
      staticClass: "owned-list",
      attrs: {
        "columns": _vm.resourceColumns,
        "rows": _vm.getParsedWithSummary(brigade.employees),
        "show-search": false,
        "show-pagination": false,
        "action-items": _vm.employeeEditActions,
        "show-action-button": _vm.editable
      },
      scopedSlots: _vm._u([{
        key: "row",
        fn: function (_ref3) {
          var column = _ref3.column,
            row = _ref3.row;
          return [column.field === 'full_name' && !row.isSummary ? _c('span', [_c('cadre-employee-label', {
            attrs: {
              "name-only": "",
              "data": row.cadre_employee
            }
          })], 1) : column.field === 'total_value' && row.isSummary ? _c('span', {
            staticClass: "summary-cell"
          }, [_vm._v(" " + _vm._s(row.total_value) + " ")]) : _vm._e()];
        }
      }], null, true)
    }) : _vm._e()], 1);
  }), 0);

}
var staticRenderFns = []

export { render, staticRenderFns }