<template>
  <div class="investments-demand-details">

    <div class="investments-demand-details-header">
      {{ material }}
    </div>

    <vs-form-header large
      :with-button="editable"
      text="Zapotrzebowanie"
      button-text="+ Dodaj zapotrzebowanie"
      @action="addDemand"
      v-if="demands.length"
    />

    <authorization-guard
      permission="investment.requirement.index"
    >
      <vs-table
        v-if="demands.length"
        class="demand-list"
        :columns="demandColumns"
        :rows="demandsWithSummary"
        :show-search="false"
        :show-pagination="false"
        :action-items="demandActionItems"
        :show-action-button="editable"
      >
        <template v-slot:row="{ column, row }" >

          <span v-if="column.field === 'class'">
            {{ row.name }}
          </span>

          <span v-if="column.field === 'price' && !row.isSummary">
            {{ Number(row.price).toLocaleString() }} PLN
          </span>

          <span v-if="column.field === 'price' && row.isSummary">
            Łącznie:
          </span>

          <span v-if="column.field === 'quantity'">
            <template v-if="row.isSummary">
              <span class="summary-cell">
                {{ row.totalQuantity.toLocaleString() }}
                {{ unitById(unitId)
                    ? unitById(unitId).code
                    : '' }}
              </span>
            </template>
            <template v-else>
              {{ Number(row.value).toLocaleString() }}
              {{ unitById(unitId)
                  ? unitById(unitId).code
                  : '' }}
            </template>
          </span>

          <span v-if="column.field === 'value'">
            <template v-if="row.isSummary">
              <span class="summary-cell">
                {{ row.totalValue.toLocaleString() }} PLN
              </span>
            </template>
            <template v-else>
              {{ (row.value * row.price).toLocaleString() }} PLN
            </template>
          </span>

        </template>
      </vs-table>
    </authorization-guard>

    <vs-form-header large
      :with-button="editable"
      text="Oferty"
      button-text="+ Dodaj ofertę"
      @action="addOffer"
      style="margin-top: 20px"
      v-if="offers.length"
    />

    <authorization-guard
      permission="investment.requirement.index"
    >
      <vs-table
        v-if="offers.length"
        class="offer-list"
        :columns="offerColumns"
        :rows="offers"
        :show-search="false"
        :show-pagination="false"
        :actionItems="offerActionItems"
        :show-action-button="editable"
      >
        <template v-slot:row="{ column, row }" >

          <span v-if="column.field === 'attachments'">
            <span
              v-for="attachment in row.files"
              :key="attachment.media.original_name"
              v-b-popover.hover.top="attachment.media.original_name"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            >
              <feather-icon
                icon="FileIcon"
                size="22"
                @click="() => openAttachment(attachment)"
              />
            </span>
          </span>

          <span v-else-if="column.field === 'choosing'">
            <template v-if="row.isLoading">
              <vs-loader inline />
            </template>
            <template v-else-if="row.is_accepted">
              <div class="offer-accept offer-accept--true">
                <feather-icon
                  size="18"
                  icon="CheckCircleIcon"
                />
              </div>
            </template>
            <template v-else>
              <div class="offer-accept offer-accept--false">
                <feather-icon
                  class="offer-accept-icon--false"
                  size="18"
                  icon="XCircleIcon"
                />
              </div>
            </template>
          </span>

          <span v-else-if="column.field === 'value'">
            {{ row.value.toLocaleString() }} PLN
          </span>

          <span v-else-if="column.field === 'valid_date'">
            {{ formatDate.training(row.valid_date) }}
          </span>

          <span v-else-if="column.field === 'guarantee_price_date'">
            {{ formatDate.training(row.guarantee_price_date) || 'na cały okres prac' }}
          </span>

          <span v-else-if="column.field === 'provider'">
            <crm-company-label
              :integrated="row.provider.company"
              company-type="provider"
            />
          </span>

        </template>
      </vs-table>
    </authorization-guard>

  </div>
</template>

<script>
import {
  VBPopover,
} from 'bootstrap-vue';
import VsFormHeader from '@/components/ui/vs-form/VsFormHeader.vue';
import VsTable from '@/components/ui/vs-table/Table.vue';
import i18n from '@/libs/i18n';
import formatDate from '@/libs/date-formatter';
import store from '@/store';

import Ripple from 'vue-ripple-directive';
import showToast from '@/libs/toasts';
import { deleteModal } from '@/libs/modals';
import {
  DeleteDemandError, DeleteDemandSuccess, DeleteOfferError, DeleteOfferSuccess,
  AcceptOfferSuccess, AcceptOfferError,
} from '@/libs/toasts/feedback/module/investments';
import useInvestmentsStore from '@/hooks/investments/useInvestmentsStore';
import CrmCompanyLabel from '@/components/module/crm/CrmCompanyLabel.vue';
import { mapGetters } from 'vuex';
import useInvestmentsApi from '@/hooks/investments/useInvestmentsApi';
import { computed } from 'vue';
import AuthorizationGuard from '@/components/ui/AuthorizationGuard.vue';
import VsLoader from '@/components/ui/vs-loader/VsLoader.vue';

export default {
  name: 'InvestmentsDemandDetails',
  props: {
    material: String,
    materialId: Number,
    unitId: Number,
    demands: Array,
    offers: Array,
    editable: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const tableI18n = 'investments.form.investment.tab.demand.table';

    store.dispatch('units/fetch');

    const demandsWithSummary = computed(() => {
      const withSummary = [
        ...props.demands,
        {
          hideActionButton: true,
          isSummary: true,
          totalValue: props.demands
            ?.reduce((prev, curr) => prev + Number(curr.value * curr.price), 0),
          totalQuantity: props.demands
            ?.reduce((prev, curr) => prev + Number(curr.value), 0),
        },
      ];

      return withSummary;
    });

    const {
      investmentCreateDemandModal,
      investmentEditDemandModal,
      investmentEditOfferModal,
      investmentCreateOfferModal,
    } = useInvestmentsStore();

    const demandColumns = [
      { label: i18n.t(`${tableI18n}.class`), field: 'class' },
      { label: i18n.t(`${tableI18n}.price`), field: 'price' },
      { label: i18n.t(`${tableI18n}.quantity`), field: 'quantity' },
      { label: i18n.t(`${tableI18n}.value`), field: 'value' },
    ];

    const offerColumns = [
      { label: i18n.t(`${tableI18n}.provider`), field: 'provider' },
      { label: i18n.t(`${tableI18n}.attachments`), field: 'attachments' },
      { label: i18n.t(`${tableI18n}.choosing`), field: 'choosing' },
      { label: i18n.t(`${tableI18n}.valid_date`), field: 'valid_date' },
      { label: i18n.t(`${tableI18n}.guarantee_price_date`), field: 'guarantee_price_date' },
      { label: i18n.t(`${tableI18n}.value`), field: 'value' },
    ];

    // const getShortcode = () => {
    //   const user = store.getters['auth/user'];

    //   const { forename, surname } = user;
    //   const shortcode = user?.short_code;

    //   if (shortcode) return shortcode;
    //   if (forename && surname) return `${forename?.substring(0, 1)}${surname?.substring(0, 2)}`;
    //   if (forename) return `${forename?.substring(0, 3)}`;
    //   return surname?.substring(0, 3);
    // };

    const demandActionItems = [
      {
        title: 'Edytuj',
        icon: 'Edit2',
        requiresPermission: 'investment.requirement.update',
        callback: (obj) => {
          investmentEditDemandModal.value.visible = true;
          investmentEditDemandModal.value.data = obj;
        },
      },
      {
        title: 'Usuń',
        icon: 'Trash',
        requiresPermission: 'investment.requirement.destroy',
        callback: async (obj) => {
          const confirmation = await deleteModal({
            title: i18n.t('investments.modal.demand.delete.title'),
            text: i18n.t('investments.modal.demand.delete.text', {
              name: obj.class,
            }),
          });

          if (!confirmation) return false;

          useInvestmentsApi()
            .deleteRequirement(obj.id)
            .then(() => {
              showToast(DeleteDemandSuccess, { name: obj.name });
              emit('materialDeleted', obj);
            })
            .catch(() => {
              showToast(DeleteDemandError);
            });

          return true;
        },
      },
    ];

    const offerActionItems = [
      {
        title: (obj) => (obj.is_accepted ? 'Odrzuć ofertę' : 'Akceptuj ofertę'),
        icon: (obj) => (obj.is_accepted ? 'XCircle' : 'CheckCircle'),
        requiresPermission: 'investment.requirement_offer.change_accept',
        callback: (obj) => {
          emit('offer:update', { ...obj, isLoading: true });

          useInvestmentsApi()
            .toggleOfferAcceptance(obj.id)
            .then(() => {
              showToast(AcceptOfferSuccess);
              emit('offer:update', { ...obj, is_accepted: !obj.is_accepted, isLoading: false });
            })
            .catch(() => {
              showToast(AcceptOfferError);
              emit('offer:update', { ...obj, isLoading: false });
            });
        },
      },
      {
        title: 'Edytuj',
        icon: 'Edit2',
        requiresPermission: 'investment.requirement_offer.update',
        callback: (obj) => {
          investmentEditOfferModal.value.visible = true;
          investmentEditOfferModal.value.data = obj;
        },
      },
      {
        title: 'Usuń',
        icon: 'Trash',
        requiresPermission: 'investment.requirement_offer.destroy',
        callback: async (obj) => {
          const confirmation = await deleteModal({
            title: i18n.t('investments.modal.offer.delete.title'),
            text: i18n.t('investments.modal.offer.delete.text', {
              provider: obj.provider.company,
            }),
          });

          if (!confirmation) return false;

          useInvestmentsApi()
            .deleteOffer(obj.id)
            .then(() => {
              showToast(DeleteOfferSuccess);
              emit('offerDeleted', obj);
            })
            .catch(() => {
              showToast(DeleteOfferError);
            });

          return true;
        },
      },
    ];

    const addDemand = () => {
      investmentCreateDemandModal.value.visible = true;
      investmentCreateDemandModal.value.data = {
        investment_requirement_group_id: props.materialId,
      };
    };

    const addOffer = () => {
      investmentCreateOfferModal.value.visible = true;
      investmentCreateOfferModal.value.data = {
        investment_requirement_group_id: props.materialId,
      };
    };

    const openAttachment = (attachment) => {
      if (!attachment?.link) return;

      window.open(attachment.link, '_blank');
    };

    return {
      formatDate,
      demandColumns,
      offerColumns,

      demandsWithSummary,

      offerActionItems,
      demandActionItems,

      investmentCreateDemandModal,
      investmentCreateOfferModal,

      addOffer,
      addDemand,

      openAttachment,
    };
  },
  computed: {
    ...mapGetters({
      unitById: 'units/byId',
    }),
  },
  components: {
    VsFormHeader,
    VsTable,
    CrmCompanyLabel,
    AuthorizationGuard,
    VsLoader,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
};
</script>

<style lang="sass" scoped>
.offer-accept
  font-weight: 500
  display: flex
  align-items: center
  gap: 5px
  justify-content: center

  &--true
    color: #68c740
  &--false
    color: #eb344c

.summary-cell
  font-weight: 500
  font-size: 12pt

.investments-demand-details
  position: relative

  .vs-table
    max-width: 100%

  .vs-form-header
    padding-left: 0

    &-button::v-deep
      .btn
        padding-right: 0 !important

  &-header
    background: white
    border-radius: 5px
    font-size: 16pt
    padding: 5px 0
    z-index: 2
    font-weight: 600

    @media screen and (max-width: 768px)
      font-size: 18pt
      margin: 0
      padding: 0

  &:not(:first-child)
    margin-top: 60px

  &:before
    content: ''
    display: block
    width: 2px
    height: 100%
    position: absolute
    top: 0
    left: -35px
    background: rgba(128, 128, 128, 0.3)

    @media screen and (max-width: 1700px)
      display: none
</style>
