<template>
  <div class="investments-cadre-details-list">

    <div
      v-for="brigade in sortedBrigades"
      :key="brigade.id"
      class="brigade"
    >
      <vs-form-header large
        :with-button="editable"
        :text="brigade.name"
        buttonText="+ Dodaj pracownika"
        @action="() => showAssignModal(brigade)"
        buttonPermission="investment.brigade.assign_employee"
      >
        <template #subtitle>
          <div class="brigade-subtitle" v-if="brigade.is_management">
            Brygada kierownicza
          </div>
          <div class="brigade-subtitle" v-if="brigade.resource.find((v) => v.pivot.is_main === 1)">
            Maszyna główna:
            <base-resource-label
              :id="getBrigadesMainVehicle(brigade).id"
              :resource="getBrigadesMainVehicle(brigade)"
            />
          </div>
          <div class="brigade-subtitle" v-if="brigade.resource
            .filter(({ pivot }) => pivot.is_main !== 1).length">

            Zasoby dodatkowe:
            <base-resource-label
              v-for="resource in brigade.resource.filter(({ pivot }) => pivot.is_main !== 1)"
              :key="resource.id"
              :id="resource.resource.id"
              :resource="resource.resource"
            />

          </div>
        </template>

        <template #edit-button v-if="editable">
          <table-action-button
            dropdown-variant="transparent"
            :items="brigadeEditActions"
            :target="brigade"
          />
        </template>
      </vs-form-header>

      <vs-table
        v-if="brigade.employees.length"
        class="owned-list"
        :columns="resourceColumns"
        :rows="getParsedWithSummary(brigade.employees)"
        :show-search="false"
        :show-pagination="false"
        :action-items="employeeEditActions"
        :show-action-button="editable"
      >
        <template v-slot:row="{ column, row }" >
          <span v-if="column.field === 'full_name' && !row.isSummary">
            <cadre-employee-label name-only
              :data="row.cadre_employee"
            />
          </span>
          <span
            v-else-if="column.field === 'total_value' && row.isSummary"
            class="summary-cell"
            >
            {{ row.total_value }}
          </span>
        </template>
      </vs-table>
    </div>

  </div>
</template>

<script>
import VsFormHeader from '@/components/ui/vs-form/VsFormHeader.vue';
import VsTable from '@/components/ui/vs-table/Table.vue';
import formatDate from '@/libs/date-formatter';
import useInvestmentsStore from '@/hooks/investments/useInvestmentsStore';
import i18n from '@/libs/i18n';
import useInvestmentsApi from '@/hooks/investments/useInvestmentsApi';
import showToast from '@/libs/toasts';
import {
  DeleteBrigadeError, DeleteBrigadeSuccess, DeleteEmployeeError, DeleteEmployeeSuccess,
} from '@/libs/toasts/feedback/module/investments';
import { deleteModal } from '@/libs/modals';
import TableActionButton from '@/components/ui/vs-table/TableActionButton.vue';
import { computed } from 'vue';
import BaseResourceLabel from '../../base/resource/BaseResourceLabel.vue';
import CadreEmployeeLabel from '../../cadre/ui/CadreEmployeeLabel.vue';

export default {
  name: 'InvestmentsCadreDetailsList',
  props: {
    brigades: {
      type: Array,
      default: () => [],
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const {
      investmentEditEmployeeModal,
      investmentEditBrigadeModal,
      investmentAssignEmployeeModal,
    } = useInvestmentsStore();

    // eslint-disable-next-line no-unused-vars
    const getParsedEmployees = (employees) => employees.map((employee) => {
      const dailyCost = employee?.cadre_position?.actual_daily_cost ?? 0;
      const days = employee?.resource_data?.days_number ?? 0;
      const totalValue = dailyCost * days;

      return {
        ...employee,
        brigade_id: employee.investment_brigade_id,
        full_name: employee.cadre_employee.full_name,
        position: employee.cadre_position.name,
        date_from: formatDate.training(employee.resource_data.scheduler.start_date),
        date_to: formatDate.training(employee.resource_data.scheduler.end_date),
        price_day: `${dailyCost.toLocaleString()} PLN`,
        work_days: `${days} dni`,
        total_value: `${totalValue.toLocaleString()} PLN`,
        rawTotalValue: totalValue,
      };
    });

    const getParsedWithSummary = (employees) => {
      const parsedEmployees = getParsedEmployees(employees);
      const totalValue = parsedEmployees.reduce((prev, curr) => prev + curr.rawTotalValue, 0);

      return [
        ...parsedEmployees,
        {
          hideActionButton: true,
          isSummary: true,
          work_days: 'Łącznie:',
          total_value: `${totalValue.toLocaleString()} PLN`,
        },
      ];
    };

    const employeeEditActions = [
      {
        title: 'Edytuj',
        icon: 'Edit2',
        requiresPermission: 'investment.brigade.edit_employee',
        callback: (obj) => {
          investmentEditEmployeeModal.value.data = { ...obj };
          investmentEditEmployeeModal.value.visible = true;
        },
      },
      {
        title: 'Usuń',
        icon: 'Trash',
        requiresPermission: 'investment.brigade.detach_employee',
        callback: async (obj) => {
          const confirmation = await deleteModal({
            title: i18n.t('investments.modal.employee.delete.title'),
            text: i18n.t('investments.modal.employee.delete.text', {
              name: obj.full_name,
            }),
          });

          if (!confirmation) return false;

          useInvestmentsApi()
            .detachEmployee(obj.investment_brigade_id, obj.cadre_employee_id)
            .then(() => {
              showToast(DeleteEmployeeSuccess, { name: obj.cadre_employee?.full_name });
              emit('delete', obj);
            })
            .catch(() => {
              showToast(DeleteEmployeeError);
            });
          return true;
        },
      },
    ];

    const tableI18n = 'investments.form.investment.tab.cadre.table';
    const resourceColumns = [
      { label: i18n.t(`${tableI18n}.position`), field: 'position' },
      { label: i18n.t(`${tableI18n}.full_name`), field: 'full_name' },
      { label: i18n.t(`${tableI18n}.date_from`), field: 'date_from' },
      { label: i18n.t(`${tableI18n}.date_to`), field: 'date_to' },
      { label: i18n.t(`${tableI18n}.price_day`), field: 'price_day' },
      { label: i18n.t(`${tableI18n}.work_days`), field: 'work_days' },
      { label: i18n.t(`${tableI18n}.total_value`), field: 'total_value' },
    ];

    const showAssignModal = ({ id }) => {
      investmentAssignEmployeeModal.value.data = { brigade_id: id };
      investmentAssignEmployeeModal.value.visible = true;
    };

    const brigadeEditActions = [
      {
        title: 'Edytuj',
        icon: 'Edit2',
        requiresPermission: 'investment.brigade.update',
        callback: (obj) => {
          investmentEditBrigadeModal.value.data = { ...obj };
          investmentEditBrigadeModal.value.visible = true;
        },
      },
      {
        title: 'Usuń',
        icon: 'Trash',
        requiresPermission: 'investment.brigade.destroy',
        callback: async (obj) => {
          const confirmation = await deleteModal({
            title: i18n.t('investments.modal.brigade.delete.title'),
            text: i18n.t('investments.modal.brigade.delete.text', {
              name: obj.name,
            }),
          });

          if (!confirmation) return false;

          useInvestmentsApi()
            .deleteBrigade(obj.id)
            .then(() => {
              showToast(DeleteBrigadeSuccess, { name: obj.name });
              emit('brigadeDelete', obj);
            })
            .catch(() => {
              showToast(DeleteBrigadeError);
            });
          return true;
        },
      },
    ];

    const sortedBrigades = computed(() => [...props.brigades]
      .sort((a, b) => {
        if (a.is_management || b.is_management) return b.is_management - a.is_management;
        return !!b.investment_base_vehicle_id - !!a.investment_base_vehicle_id;
      }));

    const getBrigadesMainVehicle = (brigade) => brigade.resource
      ?.find(({ pivot }) => pivot.is_main === 1).resource;

    return {
      getParsedWithSummary,
      employeeEditActions,
      brigadeEditActions,
      showAssignModal,
      resourceColumns,
      sortedBrigades,
      getBrigadesMainVehicle,
    };
  },
  components: {
    VsFormHeader,
    VsTable,
    TableActionButton,
    BaseResourceLabel,
    CadreEmployeeLabel,
  },
};
</script>

<style lang="sass" scoped>
.summary-cell
  font-weight: 500
  font-size: 12pt

.brigade
  margin-top: 25px

.brigade-subtitle
  display: flex
  align-items: center
  font-size: 10pt

  .base-resource-label
    margin-left: 9px
</style>
