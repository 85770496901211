<template>
  <div class="investments-resource-details-list">
    <vs-form-header large
      :with-button="editable"
      text="Własne"
      button-text="+ Dodaj"
      @action="investmentAssignResourceModal.visible = true"
      v-if="ownedResources.length > 1"
      class="list-header"
    />

    <vs-table
      v-if="ownedResources.length > 1"
      class="owned-list"
      :columns="resourceColumns"
      :rows="ownedResources"
      :show-search="false"
      :show-pagination="false"
      :action-items="actionButtons"
      :show-action-button="editable"
    >
      <template v-slot:row="{ row, column }" >
        <span v-if="column.field === 'name' && !row.isSummary">
          <base-resource-label
            :resource="row.resource"
          />
        </span>
        <span
          v-else-if="column.field === 'total_value' && row.isSummary"
          class="summary-cell"
          >
          {{ row.total_value }}
        </span>
        <span
          v-else-if="column.field === 'type' && !row.isSummary"
          >
          {{ $t(`base.global.resourceType.${row.resource.type.slug}`) }}
        </span>
        <span
          v-else-if="column.field === 'technology' && !row.isSummary"
          >
          {{ row.technology?.name }}
        </span>
      </template>
    </vs-table>

    <vs-form-header large
      :with-button="editable"
      text="Wynajmowane"
      button-text="+ Dodaj"
      @action="investmentAssignResourceModal.visible = true"
      v-if="rentedResources.length > 1"
      class="list-header"
    />

    <vs-table
      v-if="rentedResources.length > 1"
      class="rented-list"
      :columns="resourceColumns"
      :rows="rentedResources"
      :show-search="false"
      :show-pagination="false"
      :action-items="actionButtons"
      :show-action-button="editable"
    >
      <template v-slot:row="{ row, column }" >
        <span v-if="column.field === 'name' && !row.isSummary">
          <base-resource-label
            :resource="row.resource"
            :id="row.typedResourceId"
          />
        </span>
        <span
          v-else-if="column.field === 'total_value' && row.isSummary"
          class="summary-cell"
          >
          {{ row.total_value }}
        </span>
        <span
          v-else-if="column.field === 'type' && !row.isSummary"
          >
          {{ $t(`base.global.resourceType.${row.resource.type.slug}`) }}
        </span>
        <span
          v-else-if="column.field === 'technology' && !row.isSummary"
          >
          {{ row.technology?.name }}
        </span>
      </template>
    </vs-table>
  </div>
</template>

<script>
import VsFormHeader from '@/components/ui/vs-form/VsFormHeader.vue';
import VsTable from '@/components/ui/vs-table/Table.vue';
import formatDate from '@/libs/date-formatter';
import useInvestmentsStore from '@/hooks/investments/useInvestmentsStore';
import useInvestmentsApi from '@/hooks/investments/useInvestmentsApi';
import showToast from '@/libs/toasts';
import { DeleteResourceError, DeleteResourceSuccess } from '@/libs/toasts/feedback/module/investments';
import i18n from '@/libs/i18n';
import { deleteModal } from '@/libs/modals';
import { computed } from 'vue';
import BaseResourceLabel from '../../base/resource/BaseResourceLabel.vue';

export default {
  name: 'InvestmentsResourceDetailsList',
  props: {
    investment: {
      type: Object,
      default: () => ({}),
    },
    resources: {
      type: Array,
      default: () => [],
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const { investmentAssignResourceModal, investmentEditResourceModal } = useInvestmentsStore();

    const getResourceTableRow = (res) => {
      const priceDay = res.resource?.actual_daily_cost ?? 0;
      const workDays = res.resource_data?.days_number;
      const totalValue = priceDay * workDays;

      return {
        ...res,
        name: res.resource.name,
        type: res.resource.resource_type_name === 'VEHICLE_TYPE'
          ? 'Maszyna' : 'Sprzęt',
        resource: res.resource,
        category: res.resource?.category?.name,
        date_from: formatDate.training(res.resource_data?.scheduler?.start_date),
        date_to: formatDate.training(res.resource_data?.scheduler?.end_date),
        price_day: `${priceDay.toLocaleString()} PLN`,
        work_days: `${workDays} dni`,
        total_value: `${totalValue.toLocaleString()} PLN`,

        investment_id: props.investment.id,
        typedResourceId: res.id,
        days_number: res.resource_data?.days_number,
        scheduler: {
          ...res.resource_data?.scheduler,
        },
      };
    };

    const ownedResources = computed(() => {
      const owned = props.resources
        .filter((resource) => resource?.resource?.group?.name !== 'Wynajmowane');

      const rows = owned.map(getResourceTableRow);

      const totalValue = owned.reduce((prev, curr) => {
        const priceDay = curr.resource?.actual_daily_cost ?? 0;
        const workDays = curr.resource_data?.days_number ?? 0;

        return prev + (priceDay * workDays);
      }, 0);

      return [
        ...rows,
        {
          hideActionButton: true,
          isSummary: true,
          work_days: 'Łącznie:',
          total_value: `${totalValue.toLocaleString()} PLN`,
        },
      ];
    });

    const rentedResources = computed(() => {
      const rented = props.resources
        .filter((resource) => resource?.group?.name === 'Wynajmowane');

      const rows = rented.map(getResourceTableRow);

      const totalValue = rented.reduce((prev, curr) => {
        const priceDay = curr.resource?.actual_daily_cost ?? 0;
        const workDays = curr.resource_data?.days_number ?? 0;

        return prev + (priceDay * workDays);
      }, 0);

      return [
        ...rows,
        {
          hideActionButton: true,
          isSummary: true,
          work_days: 'Łącznie:',
          total_value: `${totalValue.toLocaleString()} PLN`,
        },
      ];
    });

    const tableI18n = 'investments.form.investment.tab.resource.table';
    const resourceColumns = [
      { label: i18n.t(`${tableI18n}.name`), field: 'name' },
      { label: i18n.t(`${tableI18n}.type`), field: 'type' },
      { label: i18n.t(`${tableI18n}.category`), field: 'category' },
      { label: i18n.t(`${tableI18n}.technology`), field: 'technology' },
      { label: i18n.t(`${tableI18n}.date_from`), field: 'date_from' },
      { label: i18n.t(`${tableI18n}.date_to`), field: 'date_to' },
      { label: i18n.t(`${tableI18n}.price_day`), field: 'price_day' },
      { label: i18n.t(`${tableI18n}.work_days`), field: 'work_days' },
      { label: i18n.t(`${tableI18n}.total_value`), field: 'total_value' },
    ];

    const actionButtons = [
      {
        title: 'Edytuj',
        icon: 'Edit2',
        callback: (obj) => {
          investmentEditResourceModal.value.data = { ...obj };
          investmentEditResourceModal.value.visible = true;
        },
      },
      {
        title: 'Usuń',
        icon: 'Trash',
        callback: async (obj) => {
          const confirmation = await deleteModal({
            title: i18n.t('investments.modal.resource.delete.title'),
            text: i18n.t('investments.modal.resource.delete.text', {
              name: obj.name,
            }),
          });

          if (!confirmation) return false;

          useInvestmentsApi()
            .deleteResource(obj.id)
            .then(() => {
              showToast(DeleteResourceSuccess, { name: obj?.name });
              emit('materialDeleted', obj);
            })
            .catch(({ response }) => {
              showToast(DeleteResourceError, null, null, {
                description: response.data.message,
              });
            });

          return true;
        },
      },
    ];

    return {
      actionButtons,
      ownedResources,
      rentedResources,
      resourceColumns,
      investmentAssignResourceModal,
    };
  },
  components: {
    VsTable,
    VsFormHeader,
    BaseResourceLabel,
  },
};
</script>

<style lang="sass" scoped>
.investments-resource-details-list
  overflow: auto
  :deep(.vs-table)
    width: 100%

.list-header
  margin-top: 25px

.summary-cell
  font-weight: 500
  font-size: 12pt
</style>
