var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "investments-resource-details-list"
  }, [_vm.ownedResources.length > 1 ? _c('vs-form-header', {
    staticClass: "list-header",
    attrs: {
      "large": "",
      "with-button": _vm.editable,
      "text": "Własne",
      "button-text": "+ Dodaj"
    },
    on: {
      "action": function ($event) {
        _vm.investmentAssignResourceModal.visible = true;
      }
    }
  }) : _vm._e(), _vm.ownedResources.length > 1 ? _c('vs-table', {
    staticClass: "owned-list",
    attrs: {
      "columns": _vm.resourceColumns,
      "rows": _vm.ownedResources,
      "show-search": false,
      "show-pagination": false,
      "action-items": _vm.actionButtons,
      "show-action-button": _vm.editable
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (_ref) {
        var _row$technology;
        var row = _ref.row,
          column = _ref.column;
        return [column.field === 'name' && !row.isSummary ? _c('span', [_c('base-resource-label', {
          attrs: {
            "resource": row.resource
          }
        })], 1) : column.field === 'total_value' && row.isSummary ? _c('span', {
          staticClass: "summary-cell"
        }, [_vm._v(" " + _vm._s(row.total_value) + " ")]) : column.field === 'type' && !row.isSummary ? _c('span', [_vm._v(" " + _vm._s(_vm.$t(`base.global.resourceType.${row.resource.type.slug}`)) + " ")]) : column.field === 'technology' && !row.isSummary ? _c('span', [_vm._v(" " + _vm._s((_row$technology = row.technology) === null || _row$technology === void 0 ? void 0 : _row$technology.name) + " ")]) : _vm._e()];
      }
    }], null, false, 1988588873)
  }) : _vm._e(), _vm.rentedResources.length > 1 ? _c('vs-form-header', {
    staticClass: "list-header",
    attrs: {
      "large": "",
      "with-button": _vm.editable,
      "text": "Wynajmowane",
      "button-text": "+ Dodaj"
    },
    on: {
      "action": function ($event) {
        _vm.investmentAssignResourceModal.visible = true;
      }
    }
  }) : _vm._e(), _vm.rentedResources.length > 1 ? _c('vs-table', {
    staticClass: "rented-list",
    attrs: {
      "columns": _vm.resourceColumns,
      "rows": _vm.rentedResources,
      "show-search": false,
      "show-pagination": false,
      "action-items": _vm.actionButtons,
      "show-action-button": _vm.editable
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (_ref2) {
        var _row$technology2;
        var row = _ref2.row,
          column = _ref2.column;
        return [column.field === 'name' && !row.isSummary ? _c('span', [_c('base-resource-label', {
          attrs: {
            "resource": row.resource,
            "id": row.typedResourceId
          }
        })], 1) : column.field === 'total_value' && row.isSummary ? _c('span', {
          staticClass: "summary-cell"
        }, [_vm._v(" " + _vm._s(row.total_value) + " ")]) : column.field === 'type' && !row.isSummary ? _c('span', [_vm._v(" " + _vm._s(_vm.$t(`base.global.resourceType.${row.resource.type.slug}`)) + " ")]) : column.field === 'technology' && !row.isSummary ? _c('span', [_vm._v(" " + _vm._s((_row$technology2 = row.technology) === null || _row$technology2 === void 0 ? void 0 : _row$technology2.name) + " ")]) : _vm._e()];
      }
    }], null, false, 794553197)
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }