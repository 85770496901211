var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "investments-demand-details"
  }, [_c('div', {
    staticClass: "investments-demand-details-header"
  }, [_vm._v(" " + _vm._s(_vm.material) + " ")]), _vm.demands.length ? _c('vs-form-header', {
    attrs: {
      "large": "",
      "with-button": _vm.editable,
      "text": "Zapotrzebowanie",
      "button-text": "+ Dodaj zapotrzebowanie"
    },
    on: {
      "action": _vm.addDemand
    }
  }) : _vm._e(), _c('authorization-guard', {
    attrs: {
      "permission": "investment.requirement.index"
    }
  }, [_vm.demands.length ? _c('vs-table', {
    staticClass: "demand-list",
    attrs: {
      "columns": _vm.demandColumns,
      "rows": _vm.demandsWithSummary,
      "show-search": false,
      "show-pagination": false,
      "action-items": _vm.demandActionItems,
      "show-action-button": _vm.editable
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (_ref) {
        var column = _ref.column,
          row = _ref.row;
        return [column.field === 'class' ? _c('span', [_vm._v(" " + _vm._s(row.name) + " ")]) : _vm._e(), column.field === 'price' && !row.isSummary ? _c('span', [_vm._v(" " + _vm._s(Number(row.price).toLocaleString()) + " PLN ")]) : _vm._e(), column.field === 'price' && row.isSummary ? _c('span', [_vm._v(" Łącznie: ")]) : _vm._e(), column.field === 'quantity' ? _c('span', [row.isSummary ? [_c('span', {
          staticClass: "summary-cell"
        }, [_vm._v(" " + _vm._s(row.totalQuantity.toLocaleString()) + " " + _vm._s(_vm.unitById(_vm.unitId) ? _vm.unitById(_vm.unitId).code : '') + " ")])] : [_vm._v(" " + _vm._s(Number(row.value).toLocaleString()) + " " + _vm._s(_vm.unitById(_vm.unitId) ? _vm.unitById(_vm.unitId).code : '') + " ")]], 2) : _vm._e(), column.field === 'value' ? _c('span', [row.isSummary ? [_c('span', {
          staticClass: "summary-cell"
        }, [_vm._v(" " + _vm._s(row.totalValue.toLocaleString()) + " PLN ")])] : [_vm._v(" " + _vm._s((row.value * row.price).toLocaleString()) + " PLN ")]], 2) : _vm._e()];
      }
    }], null, false, 981733911)
  }) : _vm._e()], 1), _vm.offers.length ? _c('vs-form-header', {
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      "large": "",
      "with-button": _vm.editable,
      "text": "Oferty",
      "button-text": "+ Dodaj ofertę"
    },
    on: {
      "action": _vm.addOffer
    }
  }) : _vm._e(), _c('authorization-guard', {
    attrs: {
      "permission": "investment.requirement.index"
    }
  }, [_vm.offers.length ? _c('vs-table', {
    staticClass: "offer-list",
    attrs: {
      "columns": _vm.offerColumns,
      "rows": _vm.offers,
      "show-search": false,
      "show-pagination": false,
      "actionItems": _vm.offerActionItems,
      "show-action-button": _vm.editable
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (_ref2) {
        var column = _ref2.column,
          row = _ref2.row;
        return [column.field === 'attachments' ? _c('span', _vm._l(row.files, function (attachment) {
          return _c('span', {
            directives: [{
              name: "b-popover",
              rawName: "v-b-popover.hover.top",
              value: attachment.media.original_name,
              expression: "attachment.media.original_name",
              modifiers: {
                "hover": true,
                "top": true
              }
            }, {
              name: "ripple",
              rawName: "v-ripple.400",
              value: 'rgba(113, 102, 240, 0.15)',
              expression: "'rgba(113, 102, 240, 0.15)'",
              modifiers: {
                "400": true
              }
            }],
            key: attachment.media.original_name
          }, [_c('feather-icon', {
            attrs: {
              "icon": "FileIcon",
              "size": "22"
            },
            on: {
              "click": function () {
                return _vm.openAttachment(attachment);
              }
            }
          })], 1);
        }), 0) : column.field === 'choosing' ? _c('span', [row.isLoading ? [_c('vs-loader', {
          attrs: {
            "inline": ""
          }
        })] : row.is_accepted ? [_c('div', {
          staticClass: "offer-accept offer-accept--true"
        }, [_c('feather-icon', {
          attrs: {
            "size": "18",
            "icon": "CheckCircleIcon"
          }
        })], 1)] : [_c('div', {
          staticClass: "offer-accept offer-accept--false"
        }, [_c('feather-icon', {
          staticClass: "offer-accept-icon--false",
          attrs: {
            "size": "18",
            "icon": "XCircleIcon"
          }
        })], 1)]], 2) : column.field === 'value' ? _c('span', [_vm._v(" " + _vm._s(row.value.toLocaleString()) + " PLN ")]) : column.field === 'valid_date' ? _c('span', [_vm._v(" " + _vm._s(_vm.formatDate.training(row.valid_date)) + " ")]) : column.field === 'guarantee_price_date' ? _c('span', [_vm._v(" " + _vm._s(_vm.formatDate.training(row.guarantee_price_date) || 'na cały okres prac') + " ")]) : column.field === 'provider' ? _c('span', [_c('crm-company-label', {
          attrs: {
            "integrated": row.provider.company,
            "company-type": "provider"
          }
        })], 1) : _vm._e()];
      }
    }], null, false, 1598893988)
  }) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }